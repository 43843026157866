import { FeatureData, ProduitData } from '@innedit/innedit';
import {
  convertProduitTypeToNode,
  DocumentType,
  FeatureType,
  ProduitNode,
} from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Content from '../../components/Content';
import Layout from '../../components/Layout';
import Produits from '../../components/Produits/Categories';

const List: FC<PageProps> = function (props) {
  const [id, setId] = useState<string>();
  const [page, setPage] = useState<number>();
  const [categorie, setCategorie] = useState<DocumentType<FeatureType>>();
  const [parent, setParent] = useState<DocumentType<FeatureType>>();
  const [children, setChildren] = useState<DocumentType<FeatureType>[]>();
  const [produits, setProduits] = useState<ProduitNode[]>();
  const [totalCount, setTotalCount] = useState<number>();
  const {
    location: { pathname, href },
  } = props;

  const nbParPage =
    parseInt(String(process.env.GATSBY_HITS_PER_PAGE), 10) || 30;

  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const url = new URL(href);
    setId(url.searchParams.get('id') as string);
    setPage(
      url.searchParams.get('page') ? Number(url.searchParams.get('page')) : 0,
    );
  }, [href]);

  useEffect(() => {
    let isMounted = true;
    const produitData = new ProduitData({
      espaceId: String(process.env.GATSBY_ID_BOUTIQUE),
    });

    produitData
      .search('*', {
        nbParPage,
        page,
        wheres: {
          'search.features': id as string,
        },
      })
      .then(result => {
        if (isMounted) {
          setTotalCount(result.nbHits);
          setProduits(
            result.hits
              .map(hit => ({
                ...convertProduitTypeToNode(hit),
                id: hit.objectID,
              }))
              .filter(p => p.isOnSale),
          );
        }

        return isMounted;
      })
      .catch(console.error);

    return () => {
      isMounted = false;
    };
  }, [id, nbParPage, page]);

  useEffect(() => {
    let isMounted = true;

    if (id) {
      const featureData = new FeatureData<FeatureType>({
        collectionName: 'features',
        espaceId: String(process.env.GATSBY_ID_BOUTIQUE),
      });

      featureData
        .findById(id as string)
        .then(snapshot => {
          if (isMounted) {
            setCategorie(snapshot);

            // On recherche le parent
            if (snapshot.parent) {
              featureData
                .findById(snapshot.parent)
                .then(snapParent => {
                  if (isMounted) {
                    setParent(snapParent);
                  }

                  return isMounted;
                })
                .catch(console.error);
            } else {
              setParent(undefined);
            }

            // On recherche les categories enfants
            featureData
              .find({
                wheres: {
                  parent: id,
                },
              })
              .then(docs => {
                if (isMounted) {
                  setChildren(docs);
                }

                return isMounted;
              })
              .catch(console.error);
          }

          return isMounted;
        })
        .catch(console.error);
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  const breakcrumbs = [];

  if (parent) {
    breakcrumbs.push({
      mobile: true,
      to: `${t('caracteristique.pathname')}?id=${parent.id}`,
      value: String(
        'en' === language && parent.traductions?.en?.libelle
          ? parent.traductions.en.libelle
          : parent.libelle,
      ),
    });
  }

  const description =
    'fr' === language
      ? categorie?.description
      : categorie?.traductions?.en.description;

  const title =
    'fr' === language ? categorie?.libelle : categorie?.traductions?.en.libelle;

  return (
    <Layout
      breadcrumbs={breakcrumbs}
      breadcrumbsTitle={breakcrumbs.length > 0 ? title : undefined}
      canonical={`${process.env.GATSBY_WEBSITE_DOMAIN_NAME}${pathname}?id=${id}`}
      head={{
        title,
        meta: [
          {
            content: description,
            name: 'description',
          },
        ],
      }}
      pathname={pathname}
      showTitle
      title={title}
    >
      <Content>
        {description && <p className="mb-12 mx-auto">{description}</p>}

        {produits === undefined && (
          <p className="text-center text-sm">{t('loading')}</p>
        )}
        {produits && 0 === totalCount && (
          <p className="text-center text-sm">
            {t(`product.infos.empty.label`)}
          </p>
        )}

        {id && produits && undefined !== totalCount && 0 < totalCount && (
          <Produits
            categories={children}
            categoryId={id}
            nbParPage={nbParPage}
            page={page}
            pathname={pathname}
            produits={produits}
            totalCount={totalCount}
          />
        )}
      </Content>
    </Layout>
  );
};

export default List;
