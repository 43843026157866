import {
  CategorieNode,
  DocumentType,
  FeatureType,
  ProduitNode,
} from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import objectHash from 'object-hash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import slug from 'slug';

import Pagination from '../Pagination/Categories';
import Item from './Item';
import Navigation from './Navigation';
import Recherche from './Recherche';

interface ProduitsProps {
  categoryId: string;
  categories?: (CategorieNode & {
    traductions?: { en?: { libelle?: string } };
  })[];
  produits?: ProduitNode[];
  itemsClassName?: string;
  nbParPage: number;
  page?: number;
  pathname: string;
  totalCount: number;
}

const Produits: FC<ProduitsProps> = function ({
  categoryId,
  categories,
  produits,
  itemsClassName,
  page = 0,
  nbParPage,
  pathname,
  totalCount,
}) {
  const {
    i18n: { language },
  } = useTranslation();
  const { t } = useTranslation();

  const handleOnSubmit = async (values: { q: string }) => {
    const path = slug(values.q);
    window.history.replaceState(
      { path },
      '',
      t('pages.recherche.pathname', { path }),
    );

    await navigate(t('pages.recherche.pathname', { path }));
  };

  return (
    <div className="flex flex-col md:flex-row space-y-12 md:space-y-0 md:space-x-12">
      <div>
        <div className="min-w-80 sticky top-6">
          <Recherche onSubmit={handleOnSubmit} />

          {categories && (
            <Navigation
              items={categories.map(child => ({
                label: String(
                  'en' === language && child.traductions?.en?.libelle
                    ? child.traductions.en.libelle
                    : child.libelle,
                ),
                to: `${t('caracteristique.pathname')}?id=${child.id}`,
              }))}
            />
          )}
        </div>
      </div>

      <div className="">
        {totalCount > 0 && (
          <>
            <div className="flex justify-between items-center">
              <span className="text-sm">
                {t(`products.infos.item.label`, { count: totalCount })}
              </span>

              <Pagination
                categoryId={categoryId}
                hitsPerPage={nbParPage}
                nbHits={totalCount}
                page={page}
                pathname={pathname}
              />
            </div>

            <ul
              className={
                itemsClassName ??
                'my-12 md:grid md:grid-cols-2 lg:grid-cols-3 gap-6 gap-y-12 md:gap-12'
              }
            >
              {produits &&
                produits.map(data => (
                  <Item key={objectHash(data)} data={data} />
                ))}
            </ul>

            <Pagination
              categoryId={categoryId}
              hitsPerPage={nbParPage}
              nbHits={totalCount}
              page={page}
              pathname={pathname}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Produits;
