import classnames from 'classnames';
import React, { FC } from 'react';

import Button from '../Button';

const Navigation: FC<{
  items: { label: string; to: string }[];
}> = ({ items }) => (
  <ul className="flex flex-row md:flex-col md:items-start mt-12 flex-wrap">
    {items.map(item => (
      <li key={item.to} className="mr-6 my-3 md:my-0">
        <Button
          className={classnames('whitespace-nowrap')}
          status="link"
          to={item.to}
        >
          {item.label}
        </Button>
      </li>
    ))}
  </ul>
);

export default Navigation;
