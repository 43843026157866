import { Link } from 'gatsby';
import React, { FC } from 'react';

import IconChevron from '../../icons/Chevron';

interface PaginationProps {
  categoryId: string;
  hitsPerPage: number;
  nbHits: number;
  page: number;
  pathname: string;
}
const Pagination: FC<PaginationProps> = ({
  categoryId,
  hitsPerPage,
  nbHits,
  page: oldPage,
  pathname,
}) => {
  const nbPages = Math.ceil(nbHits / hitsPerPage);
  if (!nbHits || 1 === nbPages) {
    return null;
  }
  const paths = pathname.slice(1).split('/');
  if ('' === paths[paths.length - 1]) {
    paths.pop();
  }

  let page = oldPage;
  let last: string;
  if (paths.length > 0) {
    last = paths.pop() as string;
    if (Number.isNaN(Number(last))) {
      // Ce n'est pas un nombre donc ce n'est pas le numéro de la page
      paths.push(last);
    } else {
      page = Number(last);
    }
  }

  const nbPage = page < 3 ? 6 - page : 3;
  const pages = [];
  for (
    let i = Math.max(0, page - nbPage);
    i < Math.min(nbPages, page + nbPage + 1);
    i += 1
  ) {
    pages.push(i);
  }

  const newPathname = `${pathname}?id=${categoryId}`;

  return (
    <ul className="flex space-x-4 items-center justify-end">
      {page > nbPage && (
        <li className="mobile">
          <Link
            aria-label="Page précédente"
            className="flex"
            data-page={0}
            to={newPathname}
          >
            <IconChevron
              className="text-light-700 transform rotate-180"
              size={12}
            />{' '}
            <IconChevron
              className="text-light-700 transform rotate-180"
              size={12}
            />
            <span className="hidden">Première page</span>
          </Link>
        </li>
      )}
      {page > 0 && (
        <li className="mobile">
          <Link
            aria-label="Page précédente"
            className="text-light-700"
            data-page={page - 1}
            to={`${newPathname}${page - 1 > 0 ? `&page=${page - 1}` : ''}`}
          >
            <IconChevron
              className="text-light-700 transform rotate-180"
              size={12}
            />
            <span className="hidden">Précédent</span>
          </Link>
        </li>
      )}

      {pages.map(p => (
        <li
          key={`${newPathname}${p}`}
          className={`${page === p ? 'mobile' : ''}`}
        >
          <Link
            aria-label={`Page ${p + 1}`}
            className={`${
              page === p
                ? '!text-secondary-700 border-secondary-700 border-b-2 mobile'
                : ''
            }`}
            data-page={page}
            to={p ? `${newPathname}&page=${p}` : newPathname}
          >
            {p + 1}
          </Link>
        </li>
      ))}
      {page < nbPages - 1 && (
        <li className="mobile">
          <Link
            aria-label="Page suivante"
            className="text-light-700"
            data-page={page + 1}
            to={`${newPathname}&page=${page + 1}`}
          >
            <IconChevron className="text-light-700" size={12} />
            <span className="hidden">Suivant</span>
          </Link>
        </li>
      )}
      {page < nbPages - nbPage - 1 && (
        <li className="mobile">
          <Link
            aria-label="Page suivante"
            className="text-light-700 flex"
            data-page={nbPages - 1}
            to={`${newPathname}&page=${nbPages - 1}`}
          >
            <IconChevron className="text-light-700" size={12} />
            <IconChevron className="text-light-700" size={12} />
            <span className="hidden">Dernière page</span>
          </Link>
        </li>
      )}
    </ul>
  );
};

export default Pagination;
